<template>
  <div>
    <b-card-group deck>
      <b-card
          header="TẠO ĐƠN VỊ TỔ CHỨC"
          header-tag="header"
      >
        <b-form v-if="show" @submit="saveForm">
          <div class="row">
            <div class="col-md-8">
              <b-form-group
                  id="input-dk-2"
                  label="Tên đơn vị tổ chức (*)"
                  label-for="ms-title"
              >
                <b-form-input
                    id="ms-title"
                    v-model="form.name"
                    maxlength="191"
                    required
                    placeholder="Tên đơn vị tổ chức"
                    :class="[{ 'is-invalid': form.errors.has('name') }]"
                ></b-form-input>
                <has-error :form="form" field="title"></has-error>
              </b-form-group>
            </div>

            <div class="col-md-8">
              <b-form-group
                  id="input-dk-2"
                  label="Loại tổ chức"
                  label-for="ms-desc"
              >
                <b-form-input
                    id="ms-desc"
                    v-model="form.short_desc"
                    required
                    placeholder="Tổ chức phi lợi nhuận"
                    min="0"
                    rows="4"
                    :class="[{ 'is-invalid': form.errors.has('short_desc') }]"
                ></b-form-input>
                <has-error :form="form" field="desc"></has-error>
              </b-form-group>
            </div>
            <div class="col-md-12">
              <b-form-group
                  id="input-dk-2"
                  label="Thông tin tổ chức"
                  label-for="ms-desc-detail"
              >
                <vue-editor required v-model="form.description"></vue-editor>
                <has-error :form="form" field="description"></has-error>
              </b-form-group>
            </div>
            <div class="col-md-12">
              <b-form-group label="Icon (*):" >
                <div>
                  <div style="height: 50px;" v-if="form.image">
                    <img
                        style="width: 38px; height: 38px"
                        :src="form.image"
                        alt=""
                    />
                  </div>
                  <b-form-file
                      @change="onImageChange($event, 'image')"
                      placeholder="chọn icon"
                      :required="form.image.length > 0?false:true"
                      accept="image/*"
                      type="file"
                      :class="[{ 'is-invalid': form.errors.has('image') }]"
                  ></b-form-file>
                  <has-error :form="form" field="image"></has-error>
                </div>
              </b-form-group>
            </div>
            <div class="col-md-12">
              <b-form-group
                  id="input-dk-2"
                  label="Kích hoạt"
                  label-for="ms-title"
              >
                <b-form-checkbox
                    id="checkbox-1"
                    v-model="form.status"
                    value="1"
                    unchecked-value="0"
                    size="lg"
                >
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12" style="text-align: right;">
              <b-button v-if="!is_edit" type="submit" variant="primary" style="margin-right: 10px;" >
                Tạo mới
              </b-button>
              <b-button v-else type="submit" variant="primary" style="margin-right: 10px;" >
                Cập nhật
              </b-button>
              <router-link v-if="is_edit" :to="'/charity/organization'">
                <b-button variant="danger">Hủy bỏ</b-button>
              </router-link>
              <b-button v-else type="reset" variant="danger">Làm mới</b-button>
            </div>
          </div>
        </b-form>
      </b-card>
    </b-card-group>

  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
import Multiselect from "vue-multiselect";
import Vue from "vue";
import { Form } from 'vform';
import {
  HasError,
} from 'vform/src/components/bootstrap5'
import { VueEditor } from "vue2-editor";
Vue.component("multiselect", Multiselect);
Vue.component(HasError.name, HasError)
const BackendRepository = RepositoryFactory.get("Backend");
//const WalletCampaignRepository = RepositoryFactory.get("comboVoucher");
const WalletRunRepository = RepositoryFactory.get("WalletRun");

export default {
  name: "FormMission",
  mixins: [Common],
  components: {
    VueEditor
  },
  data() {
    return {
      form: new Form({
        id: '',
        name: '',
        short_desc: '',
        description: '',
        status: 1,
        image: '',
      }),

      show: true,
      is_edit: false,
      is_disable: false,
    };

  },
  computed: {

  },
  watch: {
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lí tổ chức ", route: "/charities/organization" },
      { title: "Thêm tổ chức" },
    ]);
  },
  methods: {

    saveForm () {
      if (this.is_edit){
        this.updateOrganization();
        return true;
      }
      this.form.submit('post', '/charities/organization/store')
          .then(({data}) => {
            if (1 === data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: data.message,
              });
            } else {
              this.$notify({
                group: "foo",
                type: "success",
                title: data.message,
              });
              this.$router.push({ name: "organization" });
            }
          })
          .catch(() => {
            this.$notify({
              group: "foo",
              type: "error",
              title: "Vui lòng kiểm tra lại form nhập liệu.",
            });
          })
    },
    async loadOrganization(id) {
      try {
        let response = await BackendRepository.showOrganization(id);
        if (response.data.error_code === 0) {
          let data = response.data.data;

          this.form.fill(data);

        } else {
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        }
      } catch (error) {
        this.$notify({
          group: "foo",
          type: "error",
          title: error,
        });
      }
    },
    updateOrganization() {
      this.form.items = this.giftItems;
      this.form.submit('post', '/charities/organization/update/'+this.form.id)
          .then(({data}) => {
            if (1 === data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: data.message,
              });
            } else {
              this.$notify({
                group: "foo",
                type: "success",
                title: data.message,
              });
              this.$router.push({ name: "organization" });
            }
          })
          .catch((error) => {
            this.$notify({
              group: "foo",
              type: "error",
              title: error,
            });
          })
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        id: '',
        name: '',
        note: '',
        description: '',
        status: 1,
        image: '',
      };

    },
    onImageChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      let fileSize = files[0].size / 1024 / 1024; // in MiB
      if (fileSize> 2){
        this.$notify({
          group: "foo",
          type: "error",
          title:"Chọn ảnh không vượt quá 2MB",
        });
        return false;
      }
      this.images = files[0];
      this.uploadMedia();
      //this.createImage(files[0]);
    },
    uploadMedia: async function () {
      this.$bus.$emit("show-loading", true);
      const formData = new FormData();
      formData.append("file", this.images);

      WalletRunRepository.uploadMedia(formData)
          .then((response) => {
            this.$bus.$emit("show-loading", false);
            if (1 === response.data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: response.data.message,
              });
            } else {
              this.form.image = response.data.data.link;
            }
          })
          .catch((response) => {
            this.$bus.$emit("show-loading", false);
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          });
    },
  },
  created() {
    if (this.$route.params.id) {
      this.is_edit = true;
      this.loadOrganization(this.$route.params.id)
    }

  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style >
.title-block{
  color: #FF0000;
  font-size: 18px;
  font-weight: bold;
}
.datetime-picker div input{
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3F4254;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #E4E6EF;
  border-radius: 0.42rem;
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>
